body.login {
	background: #fae6d9;
	h1 {
		a {
			width: 280px;
			height: 120px;
			background: url(../img/logo@2x.png) no-repeat center center / contain;
		}
	}
	form {
		box-shadow: none;
	}
}

#login {
	box-shadow: none;
}
